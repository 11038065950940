import { LOGIN, LOGOUT, USERROLEACCOUNT, TEACHERSCHOOLLIST, ADD_STUDENT_LISTING } from "../Types/Logintypes"

const initialState = {
  userRoleAccountData: undefined,
  access_token: undefined,
  studentList: undefined,
  teacherSchoolListData: undefined
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        access_token: action.payload.access_token
      }
    case USERROLEACCOUNT:
      return {
        ...state,
        userRoleAccountData: action.payload.userRoleAccountData
      }
    case TEACHERSCHOOLLIST:
      return {
        ...state,
        teacherSchoolListData: action.payload.teacherSchoolListData
      }
    case ADD_STUDENT_LISTING:
      return {
        ...state,
        studentList: action.payload.studentList
      };
    case LOGOUT:
      return {
        access_token: undefined,
        userRoleAccountData: undefined
      }
    default:
      return state
  }
}




export default reducer
