import { CURRICULUMS, BOOKDATA, MUSICALGRADE, INSTRUMENT, ACTIVITIES, CURRENTEXRCIESEDATA, CURRENTCDATA, CURRICULUMDETAIL, ACTIVITYDETAIL, EXERCISES, EXERCISESDETAIL, ACTIVEINDEX, EXERCISESACTIVEINDEX, SECTION, STUDENTSUMMAYDATA } from "../Types/SongTypes"


const initialState = {
    curriculumsData: undefined,
    musicalgradeData: undefined,
    instrumentData: undefined,
    activitiesData: undefined,
    currentData: undefined,
    curriculumDetail: undefined,
    exercisesData: undefined,
    activeIndexData: 0,
    exercisesaActiveIndexData: 0,
    currentExrieseData: undefined,
    sectionData: undefined,
    refBookData: undefined,
    StudentSummayData: undefined








}

const reducer = (state = initialState, action) => {
    const modifiedState = { ...state }
    switch (action.type) {
        case CURRICULUMS:
            return {
                ...modifiedState,
                curriculumsData: action.payload.curriculumsData
            }
        case BOOKDATA:
            return {
                ...modifiedState,
                refBookData: action.payload.refBookData
            }

        case CURRENTCDATA:
            return {
                ...modifiedState,
                currentData: action.payload.currentData
            }
        case CURRENTEXRCIESEDATA:
            return {
                ...modifiedState,
                currentExrieseData: action.payload.currentExrieseData
            }


        case MUSICALGRADE:
            return {
                ...modifiedState,
                musicalgradeData: action.payload.musicalgradeData
            }

        case INSTRUMENT:
            return {
                ...modifiedState,
                instrumentData: action.payload.instrumentData
            }
        case ACTIVITIES:
            return {
                ...modifiedState,
                activitiesData: action.payload.activitiesData
            }

        case CURRICULUMDETAIL:
            return {
                ...modifiedState,
                curriculumDetail: action.payload
            }
        case ACTIVITYDETAIL:
            return {
                ...modifiedState,
                activityDetail: action.payload
            }

        case EXERCISES:
            return {
                ...modifiedState,
                exercisesData: action.payload.exercisesData
            }

        case EXERCISESDETAIL:
            return {
                ...modifiedState,
                exerciseDetail: action.payload
            }

        case ACTIVEINDEX:
            return {
                ...modifiedState,

                activeIndexData: action.payload.activeIndexData
            }
        case EXERCISESACTIVEINDEX:
            return {
                ...modifiedState,
                exercisesaActiveIndexData: action.payload.exercisesaActiveIndexData
            }

        case SECTION:
            return {
                ...modifiedState,
                sectionData: action.payload.sectionData
            }

        case STUDENTSUMMAYDATA:
            return {
                ...modifiedState,
                StudentSummayData: action.payload.StudentSummayData
            }




        default: { }

    }

    return modifiedState;
}

export default reducer;