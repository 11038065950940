const CURRICULUMS = 'CURRICULUMS';
const INSTRUMENT = "INSTRUMENT";
const MUSICALGRADE = "MUSICALGRADE";
const ACTIVITIES = "ACTIVITIES";
const CURRENTCDATA = "CURRENTCDATA";
const CURRICULUMDETAIL = 'CURRICULUMDETAIL';
const ACTIVITYDETAIL = 'ACTIVITYDETAIL';
const EXERCISES = "EXERCISES";
const EXERCISESDETAIL = "EXERCISESDETAIL";
const ACTIVEINDEX="ACTIVEINDEX"
const EXERCISESACTIVEINDEX="EXERCISESACTIVEINDEX"
const CURRENTEXRCIESEDATA = "CURRENTEXRCIESEDATA";
const SECTION = "SECTION";
const BOOKDATA = "BOOKDATA";
const  STUDENTSUMMAYDATA="STUDENTSUMMAYDATA"  








export {STUDENTSUMMAYDATA, SECTION,CURRICULUMS, INSTRUMENT, MUSICALGRADE, ACTIVITIES, CURRENTCDATA, CURRICULUMDETAIL, ACTIVITYDETAIL, EXERCISES, EXERCISESDETAIL,ACTIVEINDEX,EXERCISESACTIVEINDEX,CURRENTEXRCIESEDATA ,BOOKDATA}