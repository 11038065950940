import {
    RECITALADD,
    RECITALUPDATE,
    RECITALDELETE,
    LESSONADD,
    LESSONUPDATE,
    LESSONDELETE,
    ADDTHEORY,
    UPDATETHEORY,
    ADDLESSONVIDEO,
    REMOVELESSONVIDEO,
    ADDOBJECTIVEVIDEO,
    REMOVEOBJECTIVEVIDEO,
    ADDBPMVIDEO,
    UPDATEBPMVIDEO,
    DELETEBPMVIDEO,
    ADDMUSESCORE,
    UPDATEMUSESCORE,
    DELETEMUSESCORE,
    OBJECTIVEQUESTIONS,
    CLASSSTUDENTLIST
  } from "../Types/RecitalTypes";

const initialState = {
    createNewRecitalData: undefined,
    updateRecitalData: undefined,
    deleteRecitalData: undefined,
    createNewLessonData: undefined,
    updateLessonData: undefined,
    deleteLessonData: undefined,
    addtheoryData: undefined,
    updatetheoryData: undefined,
    addLessonVideo: undefined,
    removeLessonVideo: undefined,
    addObjectiveVideo: undefined,
    removeObjectiveVideo: undefined,
    addBpmVideo: undefined,
    updateBpmVideo: undefined,
    DELETEBPMVIDEO: undefined,
    addMuseScore: undefined,
    updateMuseScore: undefined,
    deleteMuseScore: undefined,
    objectiveQuestionsData: undefined,
    classStudentListData: undefined,

}

const RecitalReducers = (state = initialState, action) => {
    const modifiedState = { ...state }
    switch (action.type) {
        case RECITALADD:
            return {
                ...modifiedState,
                createNewRecitalData: action.payload
            }
        case RECITALUPDATE:

            return {
                ...modifiedState,
                updateRecitalData: action.payload
            }

        case RECITALDELETE:

            return {
                ...modifiedState,
                deleteRecitalData: action.payload
            }

        case LESSONADD:

            return {
                ...modifiedState,
                createNewLessonData: action.payload
            }
        case LESSONUPDATE:

            return {
                ...modifiedState,
                updateLessonData: action.payload
            }
        case LESSONDELETE:

            return {
                ...modifiedState,
                deleteLessonData: action.payload
            }
        case ADDTHEORY:

            return {
                ...modifiedState,
                addtheoryData: action.payload
            }

        case UPDATETHEORY:

            return {
                ...modifiedState,
                updatetheoryData: action.payload
            }

        case ADDLESSONVIDEO:

            return {
                ...modifiedState,
                addLessonVideo: action.payload
            }

        case REMOVELESSONVIDEO:
            return {
                ...modifiedState,
                removeLessonVideo: action.payload
            }

        case ADDOBJECTIVEVIDEO:
            return {
                ...modifiedState,
                addObjectiveVideo: action.payload
            }

        case REMOVEOBJECTIVEVIDEO:
            return {
                ...modifiedState,
                removeObjectiveVideo: action.payload
            }

        case ADDBPMVIDEO:
            return {
                ...modifiedState,
                addBpmVideo: action.payload
            }

        case UPDATEBPMVIDEO:
            return {
                ...modifiedState,
                updateBpmVideo: action.payload
            }

        case DELETEBPMVIDEO:
            return {
                ...modifiedState,
                deleteBpmVideo: action.payload
            }

        case ADDMUSESCORE:
            return {
                ...modifiedState,
                addMuseScore: action.payload
            }

        case UPDATEMUSESCORE:
            return {
                ...modifiedState,
                updateMuseScore: action.payload
            }

        case DELETEMUSESCORE:
            return {
                ...modifiedState,
                deleteMuseScore: action.payload
            }
        case OBJECTIVEQUESTIONS:

            return {
                ...modifiedState,
                objectiveQuestionsData: action.payload.objectiveQuestionsData
            }
        case CLASSSTUDENTLIST:
            console.log("payload ::: ",action.payload)
            return {
                ...modifiedState,
                classStudentListData: action.payload.classStudentListData
            }

        default: { }
    }

    return modifiedState;
}


export default RecitalReducers;
