// src/App.js

import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import Routes from './routes';
import history from './services/history';
// import { requestPermission } from './requestPermission';

export default class App extends Component {
  // componentDidMount() {
  //   requestPermission();
  // }

  render() {
    return (
      <Router history={history}>
        <Routes/>
      </Router>
    );
  }
}
